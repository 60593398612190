.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Slight transparency for blur effect */
    backdrop-filter: blur(10px); /* Blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
  }
  
  .modal-content {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 15px;
    width: 90%;
    max-width: 400px;
    color: white;
    position: relative;
    text-align: center;
  }
  .karsho-description {
    font-size: 0.9rem;
    color: #ccc;
    margin-bottom: 15px;
    text-align: left;
    line-height: 1.5;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  /* Input containers with icons */

  .input-container {
    position: relative;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .input-container input {
    flex: 1;
    background: none;
    border: none;
    color: white;
    padding: 10px;
    font-size: 1rem;
    outline: none;
  }
  
  .input-container input::placeholder {
    color: gray;
  }
  
  .icon {
    color: gray;
    font-size: 1.2rem;
  }
  
  /* Custom square upload button */
  .custom-upload-button {
    width: 80px;
    height: 80px;
    border: 2px dotted rgba(255, 255, 255, 0.2); /* Dotted border */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    background: rgba(2, 2, 2, 0.2); /* Slight transparency with blur */
    margin: 20px auto;
    transition: background 0.3s ease;
  }
  
  .custom-upload-button:hover {
    background: rgba(255, 255, 255, 0.2); /* Slightly darker on hover */
  }
  
  button[type="submit"] {
    background-color: #ff9900;
    border: none;
    border-radius: 50px;
    color: ccc;
    padding: 8px 16px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
  }
  button[type="submit"]:hover {
    background-color: #e88e00; /* Regular hover color */
  }
  
  button:disabled {
    background-color: gray; /* Disabled button color */
    cursor: not-allowed;    /* Change the cursor to indicate it is not clickable */
  }
  
  /* Prevent hover effects on disabled buttons */
  button:disabled:hover {
    background-color: gray;  /* Keep the background color gray on hover */
    cursor: not-allowed;     /* Ensure the cursor remains 'not-allowed' on hover */
  }  
  
  /* Media preview with remove button */
  .media-preview-container {
    margin-top: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .media-preview {
    max-height: 250px;
    max-width: 100%;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .remove-preview {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .media-preview img,
  .media-preview video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  /* Fullscreen modal for media */
  .fullscreen-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
  }
  
  .fullscreen-media {
    max-width: 80vw;
    max-height: 80vh;
    object-fit: contain; /* Ensure media fits within the modal without covering */
  }
  
  .close-fullscreen {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .label-left {
    margin-top: 15px;
    font-size: 0.75rem;
    color: #888;
    text-align: left;
  }
  .terms-label {
    margin-top: 15px;
    font-size: 0.85rem;
    color: #888;
    text-align: center;
  }
  
  .terms-label a {
    color: #ff9900;
    text-decoration: none;
  }
  
  .terms-label a:hover {
    text-decoration: underline;
  }
  .terms-link {
    background: none;
    border: none;
    color: #ff9900;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .terms-link:hover {
    color: #bf5f00;
  }
  
  .status-icon {
    position: absolute;
    right: 10px;
    color: gray;
    font-size: 1.2rem;
  }
  
  .valid {
    color: green;
  }
  
  .invalid {
    color: red;
  }
  .caption-input {
    width: 100%;
    background: none;
    border: none;
    color: white;
    font-size: .8rem;
    text-align: left;
    margin-bottom: 10px;
    padding: 5px;
    outline: none;
  }
  
  .caption-input::placeholder {
    color: rgba(255, 255, 255, 0.5); /* A slightly transparent white for the placeholder */
  }
  