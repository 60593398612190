* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.content {
  margin-left: 30px;
  margin-right: 30px;
}

.app-container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}
.video-background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: -2; /* Ensure it's behind the video */
}
.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fallback-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

@font-face {
  font-family: 'KARSHO';
  src: url('./fonts/KARSHO.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.heading {
  font-family: 'KARSHO', sans-serif; /* Use the custom font */
  font-size: 3rem; /* Adjust size as needed */
  color: #FF5733;
}

.tagline {
  /* font-family: 'KARSHO', sans-serif; */
  /* color: #FF5733; */
  font-size: .8rem;
  font-weight: 500;
  color: white;
  margin-bottom: 10px;
}
.subheading {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 20px;
}

.buttons button {
  margin: 10px;
  margin-left: 0;
  margin-right: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  background-color: rgba(255, 87, 51, 0.7); /* Orange with 70% opacity */
  backdrop-filter: blur(10px); /* Blur effect */
  color: white;
  cursor: pointer;
  border-radius: 50px;
  border-bottom-left-radius: 0px;
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

/* Optional: On hover, increase opacity for more contrast */
.buttons button:hover {
  background-color: rgba(255, 87, 51, 0.9); /* Slightly less transparent on hover */
}


.countdown {
  position: absolute;
  top: 20px;
  color: white;
  font-size: .8rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
}
.footer {
  text-align: center;
  padding: 10px;
  padding-bottom: 20px;
  color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.play-pause-button {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  z-index: 100;
}

.play-pause-button:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 600px) {
  .play-pause-button {
    padding: 6px 10px;
    font-size: 8px;
  }
}