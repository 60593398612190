.terms-container {
    padding: 20px;
    /* max-width: 800px; */
    margin: 0 auto;
  }
  
  .terms-section {
    margin-bottom: 20px;
  }
  
  .terms-heading {
    font-size: 20px;
    font-weight: bold;
    color: '#fff';
    margin-bottom: 10px;
  }
  
  .terms-subheading {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .terms-paragraph {
    font-size: 12px;
    color: #fff;
    line-height: 1.6;
  }
  
  .terms-list {
    list-style-type: disc;
    padding-left: 20px;
    color: #fff;
  }
  
  .terms-list li {
    font-size: 12px;
    margin-bottom: 10px;
  }
  
  .bold {
    font-weight: bold;
  }
  .terms-list .terms-paragraph {
    margin-bottom: 5px;
  }  