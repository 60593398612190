.terms-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.terms-modal-content {
  background-color: #111; /* Black background */
  color: white; /* White text */
  padding: 20px;
  width: 90%;
  max-width: 600px;
  max-height: 80%;
  border-radius: 10px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.terms-modal-content h3 {
  margin-top: 0;
  color: white; /* White heading text */
}

.terms-text-container {
  max-height: 600px;
  overflow-y: scroll;
  padding-right: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  color: white; /* White close button */
  cursor: pointer;
}
